import './css/App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './views/Home';
import Terms from './views/Terms';
import Footer from './views/Footer';

function App() {
  return (
    <div className="App">
      <div className="App-background">
        <div className="App-container">
          <Router>

            <Switch>
              <Route exact path="/">
                <Home />
              </Route>

              <Route path="/terms">
                <Terms />
              </Route>
            </Switch>

            <Footer />
            
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;
