function Terms() {
  return (
    <>
      <main>
        <div className="App-display">
          <h1 className="App-no-margin">Liquid Software LLC</h1>
          <h2 className="App-no-margin">Website Terms & Conditions</h2>
          <i>Last Updated: August 31, 2021</i>

          <h3 className="App-header-margin">Terms of Use</h3>
          <p className="App-bottom-margin sm">
            By accessing this website and any materials presented herein (the “Site”), you accept in their entirety and without modification the following terms and conditions (“Terms of Use”) pertaining to the use of the Site. Liquid Software LLC (“Liquid Software”) reserves the right to change the terms, conditions and notices under which the Site is offered without notice at any time. Liquid Software reserves the right to change the terms, conditions and notices under which the Site is offered without notice at any time. 
            <br/><br/>
            Each use of the Site constitutes your agreement to be bound by the then-current terms and conditions set forth in these Terms of Use. If you do not agree with the Terms of Use, you are not granted permission to access or otherwise use the Site.
          </p>

          <h3 className="App-header-margin">Privacy Policy</h3>
          <p className="App-bottom-margin sm">
            When you visit the website or use our services or application, we collect information sent to us by your computer, mobile phone, or other access devices. This information may include your IP address, device information including, but not limited to, identifier, name and type, operating system, location, mobile network information and standard web log information, such as your browser type, traffic to and from our site and the pages you accessed on our website.
            <br/><br/>
            When you use our services, we collect information about your activities on our website and we may collect information about your computer or other access devices for fraud prevention purposes.
            <br/><br/>
            In order to help protect you from fraud and misuse of your personal information, we may collect information about your use and interaction with our website or services. For example, we may evaluate your computer, mobile phone or other access devices to identify any malicious software or activity that may affect the availability of our services.
            <br/><br/>
            Finally, we may collect additional information from or about you in other ways such as contacts with our customer support team.
          </p>
        </div>
      </main>
    </>
  )
}

export default Terms;