import brand from '../img/liquid-brand.png';
import {FaGithub, FaLinkedin} from 'react-icons/fa';

function Home() {
  return (
    <>
      <main className="center">
        <img 
          src={brand} 
          className="App-brand" 
          alt="Liquid Software" 
        />
        
        <h1 className="App-title-margin">
          Security. Scalability. Efficiency.
        </h1>

        <p className="App-body-margin">
          Software consulting firm that specializes in blockchain, smart contracts, and distributed cloud systems 
        </p>

        <strong className="App-tagline-margin">
          info@liquid.software
        </strong>

        <div className="App-button-group">
          <a 
            className="App-icon-button App-hoverable"
            href="https://www.linkedin.com/company/liquidsoftware"
            rel="noopenner noreferrer"
            target="_blank"
          >
            <FaLinkedin  />
          </a>
          <a 
            className="App-icon-button App-hoverable" 
            href="https://github.com/LiquidSoftware"
            rel="noopenner noreferrer"
            target="_blank"
          >
            <FaGithub />
          </a>
        </div>
      </main>
    </>
  )
}

export default Home;