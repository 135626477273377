import logo from '../img/liquid-logo.png';

function Footer() {
  return (
    <footer>
      <div className="App-logo-group">
        <p className="sm App-no-margin">Liquid Software</p>
        <img src={logo} alt="liquid-logo" className="App-logo" /> 
        <p className="sm App-no-margin">
          Est. 2020
        </p>
      </div>

      <div className="App-footer-group">
        <p className="sm">
          All Rights Reserved. &nbsp;
          <a
            className="App-link App-hoverable" 
            href="/terms"
          >
            Terms & Conditions
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer;